import React from 'react';
import SLPassvid from '../assets/SLs.gif';
import lines from '../assets/lines.png';
import { Link } from 'react-router-dom';

function BannerSection() {
  return (
    <div className="flex flex-col md:flex-row justify-center items-center h-[900px] md:h-[700px] bg-opacity-25 bg-black lg:mt-[20rem] lg:ml-[10rem] lg:mr-[10rem] lg:mb-[20rem] rounded-[3rem] max-[600px]:mt-[8rem]">
      <div className="flex-1 flex justify-center items-center md:pl-8 overflow-hidden">
        <img src={SLPassvid} alt="Your Logo GIF" className="w-[200px] h-[auto] md:w-[500px] md:h-[auto] rounded-[3rem] xl:ml-[8rem] lg:mr-[6rem] max-[600px]:mt-[1rem]" />
      </div>
      <div className="flex-1 flex flex-col justify-center max-[600px]:items-center  text-left md:pl-8">
        <h1 className="text-2xl md:text-[2.6rem] font-mytom ">
          GENESIS ALPHA PASS
        </h1>
        <img src={lines} alt="Your Logo GIF" className="w-auto h-[auto] md:w-[500px] md:h-[auto] rounded-[3rem] max-[600px]:mt-[2rem] lg:mt-[2rem]" />
    
        <div className="max-[600px]:max-w-md text-left mx-auto mt-8 max-[600px]:text-left max-[600px]:mx-8 lg:mr-[6rem]">
        <p className="text-white font-mytom">Genesis Pass offers unparalleled opportunities to grow your knowledge and network within the thriving ecosystem of blockchain innovation.  <span className="block mt-2"> Join us today and take the first step towards becoming part of a dynamic and knowledgeable community of blockchain experts.</span></p>
        </div>

        <div className="flex gap-4 mt-8 max-[600px]:mb-8 ">
          <button className="text-1xl md:text-4xl font-mytom">222 SUPPLY</button>
          <button className="text-sm md:text-lg font-mytom border-r border-white"></button>
          
          <Link to="https://mint.slnotify.io/" target="_blank">
        <button className="flex items-center justify-center md:text-1xl border-4 border-[#1AB6ED] hover:bg-blue-500 hover:text-white text-[#1AB6ED] font-myfont py-1 px-2 rounded-full">
          MINT NOW
        </button>
        </Link>
        </div>
      </div>
    </div>
  );
}

export default BannerSection;
