import React from 'react';
import header from '../assets/Header.png';
import divider from '../assets/Divider.png';
import proof from '../assets/proof.png';
import moon from '../assets/moon.png';
import SL from '../assets/SLPass.png';
import discord from '../assets/DC.png';
import instagram from '../assets/IG.png';
import twitter from '../assets/tweet.png';

const About = () => {
  return (
    <div className="relative">
      <header className="flex items-center justify-center mt-8">
        <img src={header} alt="Header Image"  />
      </header>
      <div className="w-full flex items-center justify-center mt-6">
        <img src={divider} alt="Image Divider" />
      </div>
      <div className="max-w-5xl mx-auto items-center my-10 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-wrap -mx-4 mb-4">
          <div className="w-full md:w-1/2 px-4">
            <p className="text-white leading-loose">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed leo est, lacinia ut elit at, finibus vehicula magna. Ut fringilla ac neque non elementum. Fusce vestibulum sagittis velit, sit amet venenatis turpis tincidunt a. Fusce mollis massa vel nisl porttitor tempus vitae id massa. Aliquam ornare pretium odio in hendrerit.
              <br/><br/>
              Etiam fringilla augue sit amet feugiat tincidunt. Aenean volutpat non arcu eu tincidunt. Etiam ac lorem scelerisque, interdum diam in, molestie neque. Nunc porta consequat ligula eget consequat. Mauris facilisis ullamcorper purus. Integer ut nisi enim.
            </p>
          </div>
          <div className="w-full md:w-1/2 px-4">
            <img src={SL} alt="Right Image" className="w-full h-full object-cover" />
          </div>
        </div>
        <header className="flex items-center justify-center mt-8">
          <img src={proof} alt="Header Image"  />
        </header>
        <div className="w-full flex items-center justify-center mt-6">
          <img src={moon} alt="Image Divider" />
        </div>
        <div className="flex items-center justify-center mt-6">
        <p className="text-white font-myfont text-xl mr-4">OUR SOCIAL :</p>
        <a href="https://discord.com/"><img src={discord} alt="Discord Icon" className="w-8 h-8 object-contain mr-4" /></a>
        <a href="https://www.instagram.com/"><img src={instagram} alt="Instagram Icon" className="w-8 h-8 object-contain mr-4" /></a>
        <a href="https://twitter.com/"><img src={twitter} alt="Twitter Icon" className="w-8 h-8 object-contain" /></a>
</div>
      </div>
    </div>
  );
}

export default About;
