import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Logo from '../assets/Logos.png';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);

  useEffect(() => {
    const handleResize = () => setMenuOpen(false);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isDesktop = window.innerWidth > 640;

  return (
    <header className={`fixed w-full flex flex-wrap items-center justify-between px-4 ${isDesktop ? "bg-transparent" : "bg-black"}  md:bg-black md:bg-opacity-20 z-10`}>
      <a href="/">
        <img src={Logo} alt="Logo" className="md:max-w-[280px] max-w-[290px]" />
      </a>
      <button
        onClick={toggleMenu}
        className="sm:hidden text-white cursor-pointer font-myfont text-lg mr-6 hover:text-gray-400 focus:outline-none focus:text-gray-400"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-6 h-6">
          <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
        </svg>
      </button>
      <nav className={`sm:flex ${menuOpen ? "block" : "hidden"} sm:w-auto w-full mt-4 sm:mt-0 sm:items-center bg-black sm:bg-transparent`}>
        <NavItem href="/about" text="About" />
        <NavItem href="/services" text="Services" />
        <NavItem href="/events" text="Events" />
        <NavItem href="/success" text="Success" />
        <NavItem href="/team" text="Team" />
        <NavItem href="https://mint.slnotify.io/" text="Mint" />
      </nav>
    </header>
  );
};

const NavItem = ({ href, text }) => {
  const isDesktop = window.innerWidth > 640;
  const className = `text-white cursor-pointer font-myfont text-[1rem] block my-2 ${isDesktop ? 'mx-4' : 'mx-0'} sm:my-0 sm:mx-6 hover:text-gray-400`;

  return (
    <a href={href} className={className}>
      {text}
    </a>
  );
};

NavItem.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Header;
