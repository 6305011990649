import React, { useState } from 'react';


function DembeThe() {
  const [showFullReview, setShowFullReview] = useState(false);

  const toggleShowFullReview = () => {
    setShowFullReview(!showFullReview);
  };

  return (
    <div className="flex items-center border border-white mr-4 ml-4 md:h-[11rem]">
<img
  src="https://pbs.twimg.com/profile_images/1606074843505782785/8Xc2Th79_400x400.jpg"
  alt="Profile"
  className="w-[8rem] h-[8rem] rounded-full object-cover mr-4 ml-4"
/>
      <div className="flex flex-col mt-2">
        <p className="text-white mr-4">
          {showFullReview
            ? 'H2All,Sharing my journey to SL, back in 2020(Pandemic) Ive been looking forward to join SL as a Cook Group.'
          : 'H2All,Sharing my journey to SL, back in 2020(Pandemic) Ive been looking forward to join SL as a Cook Group ...'}
          {!showFullReview && (
            <button
              className="text-blue-500 hover:underline font-bold py-2 px-4 rounded-full"
              onClick={toggleShowFullReview}
            >
              Read More
            </button>
          )}
        </p>
        <div className="mt-4 max-[600px]:mb-[50px] text-left ">
          <a
            href="https://twitter.com/DembeThe"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline font-bold py-2 px-4 rounded-full "
          >
            @DembeThe
          </a>
        </div>
      </div>
      {showFullReview && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
              onClick={toggleShowFullReview}
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
        <div className="bg-black px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div
                    className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10"
                  >
<img
  src="https://pbs.twimg.com/profile_images/1606074843505782785/8Xc2Th79_400x400.jpg"
  alt="Profile"
  className="w-[5rem] h-[5rem] rounded-full object-cover mr-4 ml-4"
/>
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3
                                           className="text-lg leading-6 font-medium text-white font-mytom"
                                           id="modal-headline"
                                         >
                        DembeThe
                        </h3>
                        <div className="mt-2 overflow-y-auto max-h-[30rem] lg:mr-[20px]">
                        <p className="text-white mb-2">
                        H2All,
                        </p>
                        <p className="text-white mb-2">
Sharing my journey to SL, back in 2020(Pandemic) I've been looking forward to join SL as a Cook Group it's a more than a Year journey for me before I joined the group via Giveways December 2020(P.S. with Monthly subscription) .. Fast forward when I am already inside SL, NFT's is already a thing and someone inside SL just Sold his NFT for 20ETH(eth 4KUSD) that's (Giannis) I won 1,500 pesos(this already covers my 2 months subs) from his giveaway so no pressure for me.
</p>
<p className="text-white mb-2">
Fast forward again, I already started grinding for WL, luckily I am already inside and there's a bunch of Moderator inside to name some who helped in WL's journey are   Sins   Chambalot  they both help get me a WL's.
</p>
<p className="text-white mb-2">
Fast forward again, after getting a WL from a bullish projects tbh I don't have as in (Zero) Imagine where can I get a 0.25 to 0.3 ETH (eth 4K USD), So yeahh here comes SL mint assistance  Lance he  got me covered in here we minted my first NFT using his money and we sold it with a very good profit
</p>
<p className="text-white mb-2">
What I got so far in SL?.
 I paid paid my DEBT, I was able to buy things using money earned from SL, Paying Internet bills without touching my Web2 Salary.
 </p>
 <p className="text-white mb-2">
SL is great community, Helping me out in my WEB3 journey. Crypto trading and other stuff.
</p>
<p className="text-white mb-2">
Special thanks to founder Rev, HugeHen the great, XLR8, Godk1ng

SLNotifyIO is the best community.

I STARTED FROM NOTHING AND RUNNING PROFIT WITHOUT PRESSURE

                        </p>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div className="bg-black px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                                       type="button"
                                       className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                       onClick={toggleShowFullReview}
                                     >
                        Close
                        </button>
                        </div>
                        </div>
                        </div>
                        </div>
                        )}
                        </div>
                        );
                        }
                        
                        export default DembeThe;
