import React, { useState } from 'react';

const FolderSlide = ({ images, titles }) => {
  const [selectedImage, setSelectedImage] = useState(0);

  const handleImageClick = (index) => {
    setSelectedImage(index);
  };

  const folderContent = selectedImage !== null ? (
    <img src={images[selectedImage]} alt={titles[selectedImage]} className="h-full" />
  ) : (
    <div className="flex justify-center items-center h-full">
      <p className="text-white font-myfont">{`Selected Image: ${selectedImage + 1}`}</p>
    </div>
  );

  return (
    <div className="flex h-auto">
      <div className="w-3/4 h-3/4 bg-transparent flex justify-center items-center mt-10">
        {folderContent}
      </div>
      <div className="right-0 top-0 w-1/4 h-full bg-transparent flex flex-col justify-end">
        <div className="w-full h-12 bg-transparent flex justify-end items-center p-2 cursor-pointer" >
    
        </div>
        <div className="flex-grow overflow-y-auto">
          {images.map((image, index) => (
            <div
              key={index}
              className={`h-16 p-4 cursor-pointer ${
                selectedImage === index ? 'bg-blue-500' : ''
              }`}
              onClick={() => handleImageClick(index)}
            >
              <p className="text-white font-mytom md:text-xl text-sm ">{titles[index]}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FolderSlide;
