import React, { useState } from 'react';


function DembeThe() {
  const [showFullReview, setShowFullReview] = useState(false);

  const toggleShowFullReview = () => {
    setShowFullReview(!showFullReview);
  };

  return (
    <div className="flex items-center border border-white mr-4 ml-4 md:h-[11rem]">
<img
  src="https://pbs.twimg.com/profile_images/1624384489060442113/O_C-wYcp_400x400.jpg"
  alt="Profile"
  className="w-[8rem] h-[8rem] rounded-full object-cover mr-4 ml-4"
/>
      <div className="flex flex-col mt-2">
        <p className="text-white mr-4">
          {showFullReview
            ? 'I joined SL about a year ago and I never thought that Id be joining the NFT game since I was only.'
          : 'I joined SL about a year ago and I never thought that Id be joining the NFT game since I was only ...'}
          {!showFullReview && (
            <button
              className="text-blue-500 hover:underline font-bold py-2 px-4 rounded-full"
              onClick={toggleShowFullReview}
            >
              Read More
            </button>
          )}
        </p>
        <div className="mt-4 max-[600px]:mb-[50px] text-left ">
          <a
            href="https://twitter.com/longadogs"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline font-bold py-2 px-4 rounded-full "
          >
            @longadogs
          </a>
        </div>
      </div>
      {showFullReview && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
              onClick={toggleShowFullReview}
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
        <div className="bg-black px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div
                    className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10"
                  >
<img
  src="https://pbs.twimg.com/profile_images/1624384489060442113/O_C-wYcp_400x400.jpg"
  alt="Profile"
  className="w-[5rem] h-[5rem] rounded-full object-cover mr-4 ml-4"
/>
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3
                                           className="text-lg leading-6 font-medium text-white font-mytom"
                                           id="modal-headline"
                                         >
                        longadogs
                        </h3>
                        <div className="mt-2 overflow-y-auto max-h-[30rem] lg:mr-[20px]">
                        <p className="text-white mb-2">
                        I joined SL about a year ago and I never thought that I'd be joining the NFT game since I was only into sneaker reselling. I only got into NFTs when I saw CookingAnni's 7 digit profit from mekaverse 😂. 
                        </p>
                        <p className="text-white mb-2">
I started with 20k capital and only grinded in small NFT projects. I remember, I minted shitty projects first coz I thought that every NFT sells for a profit HAHAHAHA and I ended up losing around 5k-10k during those mints and I honestly gave up after that. After a few days, I randomly joined a giveaway on discord and for some reason, I won a whitelist. I minted that NFT and made some profit that covered most of my losses. Fast forward to around a month, I almost lost all my capital again coz I held my Hor1zon Trooper (shoutout to morty), and tbh, I never really thought that I'd earn those losses back but I still grinded a lot of projects (Digi Dragz, Crocz, Almighty Pigs, MTG, Psychedelics Anonymous, Dino Babies, Divine Wolves, etc.). 
</p>
<p className="text-white mb-2">
Fast forward to December when almost all of these projects started minting. This was the time I really started earning from NFTs and I honestly think I only started earning after grinding for almost 2 months (?) and after that, I also got whitelisted on HAPE, C0-1, and Invisible friends. And those mints finally gave me 7 digits in profits.
</p>
<p className="text-white mb-2">
I never expected to make this much at 18 years old coz I was only profiting 500-1k/pair sa shoe game 😂 
</p>
<p className="text-white mb-2">
BIGGEST THANKS TO Niana || Longa-Tok &  Lance (kahit nirug moko jk) for helping me start sa NFT game, this won't be possible without u guys ❤️ 


                        </p>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div className="bg-black px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                                       type="button"
                                       className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                       onClick={toggleShowFullReview}
                                     >
                        Close
                        </button>
                        </div>
                        </div>
                        </div>
                        </div>
                        )}
                        </div>
                        );
                        }
                        
                        export default DembeThe;
