import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import staff1 from '../assets/Rectangle 30-0.png';
import staff2 from '../assets/Rectangle 30-1.png';
import staff3 from '../assets/Rectangle 30-2.png';
import staff4 from '../assets/Rectangle 30-3.png';
import staff5 from '../assets/Rectangle 30-4.png';
import staff6 from '../assets/Rectangle 30-5.png';
import staff7 from '../assets/Rectangle 30-6.png';
import staff8 from '../assets/Rectangle 30-7.png';
import staff9 from '../assets/Rectangle 30-8.png';
import staff10 from '../assets/Rectangle 30-9.png';
import staff11 from '../assets/Rectangle 30-10.png';
import staff13 from '../assets/Rectangle 30-12.png';
import staff14 from '../assets/Rectangle 30-14.png';
import staff16 from '../assets/Rectangle 31.png';

const Team = () => {
  const sliderRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);


  const [staff] = useState([
    {
      name: "REV",
      image: staff1,
      achievements1: "Former CEO of a Startup company ",
      achievements2: "Owner and Franchiser of Several Web2 Ventures",
      achievements3: "Data Analyst",
      achievements4: "Entrepreneur",
      social: [
        { name: "Facebook", link: "https://www.facebook.com/" },
        { name: "Twitter", link: "https://twitter.com/" },
        { name: "Instagram", link: "https://www.instagram.com/" },
      ],
    },
    {
      name: "HUGEHEN",
      image: staff2,
      achievements1: "PHP Millionaire at 22 years old",
      achievements2: "Crypto Trader since 2016",
      achievements3: "Web3 Moderator and Writer",
      achievements4: "Founder of Riches to Rugs",
      social: [
        { name: "Facebook", link: "https://www.facebook.com/" },
        { name: "Twitter", link: "https://twitter.com/" },
        { name: "Instagram", link: "https://www.instagram.com/" },
      ],
    },
    {
      name: "SNEAKO",
      image: staff3,
      achievements1: "Millionaire status in the first year in NFTs",
      achievements2: "Achieved a million in sales from online businesses",
      achievements3: "Stake.com Platinum Player",
      achievements4: "",
      social: [
        { name: "Facebook", link: "https://www.facebook.com/" },
        { name: "Twitter", link: "https://twitter.com/" },
        { name: "Instagram", link: "https://www.instagram.com/" },
      ],
    },
    {
      name: "CHAMBALOT",
      image: staff4,
      achievements1: "Web3 Investor",
      achievements2: "Established Web3 Moderator",
      achievements3: "CEO of a Web2 Company",
      achievements4: "",
      social: [
        { name: "Facebook", link: "https://www.facebook.com/" },
        { name: "Twitter", link: "https://twitter.com/" },
        { name: "Instagram", link: "https://www.instagram.com/" },
      ],
    },
    {
      name: "COOKINGANNI",
      image: staff5,
      achievements1: "First NFT Millionaire in SL",
      achievements2: "Experienced Forex/Crypto Trader (2016-present)",
      achievements3: "Web3 Moderator and CM",
      achievements4: "Platinum III in Stake.com",
      social: [
        { name: "Facebook", link: "https://www.facebook.com/" },
        { name: "Twitter", link: "https://twitter.com/" },
        { name: "Instagram", link: "https://www.instagram.com/" },
      ],
    },
    {
      name: "BASTI",
      image: staff6,
      achievements1: "PHP Millionaire at 17",
      achievements2: "Lost those millions with car and land at 19",
      achievements3: "Made it back with SL and Stake.com",
      achievements4: "",
      social: [
        { name: "Facebook", link: "https://www.facebook.com/" },
        { name: "Twitter", link: "https://twitter.com/" },
        { name: "Instagram", link: "https://www.instagram.com/" },
      ],
    },
    {
      name: "FAIRYPEWPEW",
      image: staff7,
      achievements1: "CEO of Web2 businesses",
      achievements2: "Designer of multiple Web3 projects",
      achievements3: "Millionaire dropout",
      achievements4: "",
      social: [
        { name: "Facebook", link: "https://www.facebook.com/" },
        { name: "Twitter", link: "https://twitter.com/" },
        { name: "Instagram", link: "https://www.instagram.com/" },
      ],
    },
    {
      name: "SHADY",
      image: staff8,
      achievements1: "Experienced Web3 Mod",
      achievements2: "Millionaire at 22",
      achievements3: "Stake Degen",
      achievements4: "",
      social: [
        { name: "Facebook", link: "https://www.facebook.com/" },
        { name: "Twitter", link: "https://twitter.com/" },
        { name: "Instagram", link: "https://www.instagram.com/" },
      ],
    },
    {
      name: "XLR8",
      image: staff9,
      achievements1: "Millionaire at 17 through Web2,Crypto, NFTs, and Sportsbetting",
      achievements2: "Sports Analyst",
      achievements3: "Crypto/NFT Trader",
      achievements4: "Community Builder & CM",
      social: [
        { name: "Facebook", link: "https://www.facebook.com/" },
        { name: "Twitter", link: "https://twitter.com/" },
        { name: "Instagram", link: "https://www.instagram.com/" },
      ],
    },
    {
      name: "SINS",
      image: staff10,
      achievements1: "Head Mod and CM for known projects/communities",
      achievements2: "Web2 E-commerce business owner",
      achievements3: "Advisor, Alpha Caller, Social Media Manager",
      achievements4: "",
      social: [
        { name: "Facebook", link: "https://www.facebook.com/" },
        { name: "Twitter", link: "https://twitter.com/" },
        { name: "Instagram", link: "https://www.instagram.com/" },
      ],
    },
    {
      name: "ZIEE",
      image: staff11,
      achievements1: "Web3 and Web2 Developer",
      achievements2: "Unity Developer",
      achievements3: "Discord Engineer",
      achievements4: "",
      social: [
        { name: "Facebook", link: "https://www.facebook.com/" },
        { name: "Twitter", link: "https://twitter.com/" },
        { name: "Instagram", link: "https://www.instagram.com/" },
      ],
    },
    {
      name: "SOLELYTEMPTED",
      image: staff13,
      achievements1: "PHP Millionaire at 18",
      achievements2: "SL Core Member since 2018",
      achievements3: "Crypto Trader Since 2020",
      achievements4: "Successful Web2 Business Owner",
      social: [
        { name: "Facebook", link: "https://www.facebook.com/" },
        { name: "Twitter", link: "https://twitter.com/" },
        { name: "Instagram", link: "https://www.instagram.com/" },
      ],
    },
    {
      name: "VM",
      image: staff16,
      achievements1: "•Web2 Business Owner",
      achievements2: "•Head of Sneakers",
      achievements3: "•Community Leader",
      achievements4: "",
      social: [
        { name: "Facebook", link: "https://www.facebook.com/" },
        { name: "Twitter", link: "https://twitter.com/" },
        { name: "Instagram", link: "https://www.instagram.com/" },
      ],
    },

    {
      name: "JOHN DOE",
      image: staff14,
      achievements1: "Web3 Moderator & Host",
      achievements2: "Established Public Relations Associate",
      achievements3: "Experienced HR Manager",
      achievements4: "",
      social: [
        { name: "Facebook", link: "https://www.facebook.com/" },
        { name: "Twitter", link: "https://twitter.com/" },
        { name: "Instagram", link: "https://www.instagram.com/" },
      ],
    },
    
  ]);

  const settings = {
    dots: false,
    infinite: true,
    speed: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    cssEase: 'linear',
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  
  const handleMouseEnter = () => {
    setIsHovering(true);
    if (sliderRef.current) {
      sliderRef.current.slickPause();
    }
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    if (sliderRef.current) {
      sliderRef.current.slickPlay();
    }
  };

  return (
    <div className="py-[10rem]" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Slider {...settings} ref={sliderRef}>
        {staff.map((member, index) => (
          <div key={index} className="mx-auto">
            <div className={`inline-flex max-[600px] ml-[3rem] w-[21rem] h-[33rem] flex-col space-y-3.5 pt-[1.5rem] text-center px-6 bg-gray-300 rounded-3xl border-2 border-gray-400 mx-auto`}>
              <img
                className="w-[200px] h-[200px] rounded-2xl mx-auto"
                src={member.image}
                alt={member.name}
              />
              <div className="w-full h-full">
                <p className="text-[1.2rem] font-myfont text-black">{member.name}</p>
                <p className="text-[1rem] text-left font-mytom text-black">{member.achievements1}</p>
                <p className="text-[1rem] text-left font-mytom text-black">{member.achievements2}</p>
                <p className="text-[1rem] text-left font-mytom text-black">{member.achievements3}</p>
                <p className="text-[1rem] text-left font-mytom text-black">{member.achievements4}</p>
              </div>
              <div className="bottom-0 right-0">
                {member.social.map((s, i) => (
                  <a key={i} href={s.link} target="_blank" rel="noopener noreferrer">
                    {s.name === 'Twitter' && <FontAwesomeIcon icon={faTwitter} className="text-black ml-[12rem]" />}
                  </a>
                ))}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Team;