import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons';

import Success1 from '../assets/Services/1.png';
import Success2 from '../assets/Services/2.png';
import Success3 from '../assets/Services/3.png';
import Success4 from '../assets/Services/4.png';
import Success5 from '../assets/Services/5.png';
import Success6 from '../assets/Services/6.png';
import Success7 from '../assets/Services/7.png';
import Success8 from '../assets/Services/8.png';
import Success9 from '../assets/Services/9.png';
import Success10 from '../assets/Services/10.png';
import Success11 from '../assets/Services/11.png';
import Success12 from '../assets/Services/12.png';

const Services = () => {
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null);
  const [visiblePhotos, setVisiblePhotos] = useState(20);
  const photos = [
    Success1,
    Success2,
    Success3,
    Success4,
    Success5,
    Success6,
    Success7,
    Success8,
    Success9,
    Success10,
    Success11,
    Success12
  ];

  const openPhoto = (index) => {
    setSelectedPhotoIndex(index);
    document.body.style.overflow = 'hidden'; // Prevent scrolling when the image is open
  };

  const closePhoto = () => {
    setSelectedPhotoIndex(null);
    document.body.style.overflow = ''; // Restore scrolling when the image is closed
  };

  const goToPreviousPhoto = () => {
    setSelectedPhotoIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : photos.length - 1));
  };

  const goToNextPhoto = () => {
    setSelectedPhotoIndex((prevIndex) => (prevIndex < photos.length - 1 ? prevIndex + 1 : 0));
  };

  const showMorePhotos = () => {
    setVisiblePhotos((prevVisiblePhotos) => prevVisiblePhotos + 20);
  };

  return (
    <div className="container mx-auto my-[8rem]">
      <h2 className="lg:text-[5rem] text-3xl font-mytom lg:mb-[5rem] mb-4 text-left">OUR SERVICES</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
        {photos.slice(0, visiblePhotos).map((photo, index) => (
          <div key={index} className="relative">
            <img
              src={photo}
              alt={`Success ${index}`}
              className="w-full h-470 object-cover cursor-pointer"
              onClick={() => openPhoto(index)}
            />
          </div>
        ))}
      </div>

      {visiblePhotos < photos.length && (
        <button
          className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-5"
          onClick={showMorePhotos}
        >
          Show More
        </button>
      )}
      {selectedPhotoIndex !== null && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-75">
          <div className="relative">
            <button
              className="absolute top-1/2 lg:-left-11 left-8 -mt-8 text-3xl  text-white p-2 rounded-full transform -translate-y-1/2"
              onClick={goToPreviousPhoto}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <button
              className="absolute top-1/2 lg:-right-11 right-8 -mt-8  text-3xl text-white p-2 rounded-full transform -translate-y-1/2"
              onClick={goToNextPhoto}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
            <button
              className="absolute top-2 right-10 text-3xl text-white p-2 rounded-full"
              onClick={closePhoto}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <img
              src={photos[selectedPhotoIndex]}
              alt={`Success ${selectedPhotoIndex}`}
              className="w-full h-470 object-contain"
            />
          </div>
        </div>
      )}

      <h1 className="lg:text-[10rem] text-5xl font-mytom mb-4 text-right text-gray-100 ">SERVICES</h1>
    </div>
  );
};

export default Services;
