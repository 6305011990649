import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import slmint1 from '../assets/slmint1.jpg';
import slmint2 from '../assets/slmint/slmints1.jpg';
import slmint3 from '../assets/slmint/slmints2.jpg';
import slmint4 from '../assets/slmint/slmints3.jpg';
import slmint5 from '../assets/slmint/slmints4.jpg';
import slmint6 from '../assets/slmint/slmints5.jpg';
import slmint7 from '../assets/slmint/slmints6.jpg';
import slmint8 from '../assets/slmint/slmints7.jpg';
import slmint9 from '../assets/slmint/slmints8.jpg';
import sneaker1 from '../assets/sneaker1.jpg';
import sneaker2 from '../assets/sneakerhead/sneakerhead.mp4';
import meetup1 from '../assets/meetup1.jpg';
import meetup2 from '../assets/Meetups/meet1.png';
import meetup3 from '../assets/Meetups/meet2.jpg';
import meetup4 from '../assets/Meetups/meet3.jpg';
import meetup5 from '../assets/Meetups/meet4.jpeg';
import meetup6 from '../assets/Meetups/meet5.jpg';
import meetup7 from '../assets/Meetups/meet6.jpg';
import meetup8 from '../assets/Meetups/meet7.jpg';
import meetup9 from '../assets/Meetups/meet8.jpg';
import parties1 from '../assets/parties1.jpg';
import parties2 from '../assets/Parties/party1.jpg';
import parties3 from '../assets/Parties/party2.jpg';
import parties4 from '../assets/Parties/party3.jpg';
import parties5 from '../assets/Parties/party4.jpg';
import parties6 from '../assets/Parties/party5.jpg';
import parties7 from '../assets/Parties/party6.jpg';
import parties8 from '../assets/Parties/party7.jpg';


function Galla() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleOpenModal = (imageList) => {
    setImages(imageList);
    setSelectedImage(imageList[0]);
    setCurrentIndex(0);
  };

  const handleNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    setSelectedImage(images[(currentIndex + 1) % images.length]);
  };
  
  const handlePrevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    setSelectedImage(images[(currentIndex - 1 + images.length) % images.length]);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setImages([]);
    setCurrentIndex(0);
  };

  return (
    <div className="flex flex-wrap justify-center items-center w-screen h-screen mt-11 mb-[5rem] max-[600px]:mb-[10rem]">
    <div className="flex flex-wrap justify-center items-center mb-6 max-[600px]:grid max-[600px]:grid-cols-2  ">
        <ImageWithCaption
          image={slmint1}
          alt="SL MINT PARTY"
          caption="SL MINT PARTY"
          imageList={[slmint2, slmint3, slmint4, slmint5, slmint6, slmint7, slmint8, slmint9]}
          handleOpenModal={handleOpenModal}
        />
        <ImageWithCaption
          image={sneaker1}
          alt="SNEAKERHEAD"
          caption="SNEAKERHEAD"
          imageList={[sneaker2]}
          handleOpenModal={handleOpenModal}
        />
        <ImageWithCaption
          image={meetup1}
          alt="MEET UPS"
          caption="MEET UPS"
          imageList={[meetup2, meetup3, meetup4, meetup5, meetup6, meetup7, meetup8, meetup9]}
          handleOpenModal={handleOpenModal}
        />
        <ImageWithCaption
          image={parties1}
          alt="PARTIES!!"
          caption="PARTIES!!"
          imageList={[parties2, parties3, parties4, parties5, parties6, parties7, parties8]}
          handleOpenModal={handleOpenModal}
        />
      </div>
      {/* Hidden on small screens */}
      <div className="w-full h-px bg-white hidden sm:block"></div>
      <div className="w-full justify-between px-4 -mt-20 hidden sm:flex">
        <p className="font-myfont text-3xl text-white">Events</p>
        <p className="font-myfont text-3xl text-white">GALLERY</p>
      </div>
      {selectedImage && (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-gray-900 bg-opacity-75 flex items-center justify-center">
          <div className="relative">
            {selectedImage.endsWith('.mp4') ? (
              <video className="object-contain w-[20rem] max-h-full" controls>
                <source src={selectedImage} type="video/mp4" />
              </video>
            ) : (
              <img src={selectedImage} alt="Selected" className="object-contain w-[30rem] max-h-full" />
            )}
            <button className="absolute text-3xl top-1/2 transform -translate-y-1/2 left-4 p-2 rounded-full" onClick={handlePrevImage}>
            <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <button className="absolute text-3xl top-1/2 transform -translate-y-1/2 right-4 p-2 rounded-full" onClick={handleNextImage}>
            <FontAwesomeIcon icon={faArrowRight} />
            </button>
            <button className="absolute top-2 right-2  text-white text-3xl" onClick={handleCloseModal}>
            <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

function ImageWithCaption({ image, alt, caption, imageList, handleOpenModal }) {
  return (
    <div className="relative w-26 h-45 md:w-56 md:h-96 mx-4 my-5 ">
      <img src={image} alt={alt} className="object-cover w-56 h-96 cursor-pointer" onClick={() => handleOpenModal(imageList)} />
      <p className="absolute inset-x-0 bottom-0 text-2xl text-white text-center mt-2 -mb-10">{caption}</p>
    </div>
  );
}

export default Galla;
