import React, { useState } from 'react';
import discord from '../assets/DC.png';
import instagram from '../assets/IG.png';
import medium from '../assets/Medium.png';
import opensea from '../assets/Opensea.png';
import twitter from '../assets/tweet.png';
import footer from '../assets/Footer.png';
import emailjs from 'emailjs-com';


const Footer = () => {

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    const templateParams = {
      to_name: 'SLNotify Team',
      from_name: email,
      message_html: message
    };
  
    const auth = {
      user_id: 'Y16RVtjbyliN8SgG-',
      service_id: 'service_51mwevb',
      template_id: 'template_o6vxnmi'
    };
  
    emailjs.send(auth.service_id, auth.template_id, templateParams, auth.user_id)
      .then(() => {
        alert('Your message has been sent!');
        setEmail('');
        setMessage('');
      })
      .catch((error) => {
        console.error(error);
        alert('Sorry, there was an error sending your message.');
      });
  };

  return (
    <footer className="bg-gray-800 py-10 px-6 sm:px-10 lg:px-16 xl:px-20">
<div className="max-w-7xl mx-auto flex flex-col sm:flex-row justify-between items-center ">
  <div className="sm:w-1/2">
    <h1 className="text-left text-5xl font-bold">CONNECT WITH US</h1>
    <form className="text-gray-400 text-sm" onSubmit={handleSubmit}>
      <div className="mb-2">
        <label className="block font-medium text-xl mb-1" htmlFor="email">Your Email</label>
        <input
          type="email"
          id="email"
          className="w-full sm:w-[400px] bg-gray-700 border border-gray-600 px-3 py-2 rounded"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
      </div>
      <div className="mb-2">
        <label className="block font-medium text-xl mb-1" htmlFor="message">Message</label>
        <textarea
          id="message"
          className="w-full sm:w-[400px] bg-gray-700 border border-gray-600 px-3 py-2 rounded"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
        ></textarea>
      </div>
      <button className="bg-gray-600 hover:bg-gray-700 text-white px-4 py-2 rounded" type="submit">Submit</button>
    </form>
    <div className="text-sm mt-4">
      <p className="text-gray-400 mb-1 text-2xl">EMAIL US</p>
      <a href="mailto:contact@slnotify.io" className="text-white text-2xl hover:text-gray-400 focus:text-gray-400 focus:outline-none">contact@slnotify.io</a>
    </div>
  </div>
</div>

      
<div className="flex flex-col md:flex-row justify-center items-center mt-[4rem] ">
      <div className="flex-1 flex flex-col justify-center items-center  text-center md:pl-8">
        <h1 className="text-2xl md:text-[2rem] font-bold ">
        JOIN OUR COMMUNITY
        </h1>
        <div className="flex gap-1 mt-2 max-[600px]:mb-8 ">
        <img src={footer} alt="Twitter" className="w-[500px] object-contain hidden sm:block"/>
        <a href="https://twitter.com/" className=" mb-4 md:mb-0"><img src={twitter} alt="Twitter" className="w-[70px] h-[70px] object-contain"/></a>
        <a href="https://www.instagram.com/" className=" mb-4 md:mb-0"><img src={instagram} alt="Instagram Icon" className="w-[70px] h-[70px] object-contain"/></a>
        <a href="https://medium.com/" className="mb-4 md:mb-0"><img src={medium} alt="Medium" className="w-[70px] h-[70px] object-contain"/></a>
        <a href="https://twitter.com/" className="mb-4 md:mb-0"><img src={discord} alt="discord" className="w-[70px] h-[70px] object-contain"/></a>
        <a href="https://twitter.com/" className="mb-4 md:mb-0"><img src={opensea} alt="opensea" className="w-[70px] h-[70px] object-contain"/></a>
        <img src={footer} alt="Twitter" className="w-[500px] object-contain hidden sm:block"/>
  </div>
</div>

    </div>


     
    </footer>
  );
};

export default Footer;