import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons';

import	Success1	from  	'../assets/2022/Success1/Success2.png'
import	Success2	from  	'../assets/2022/Success1/Success3.png'
import	Success3	from  	'../assets/2022/Success1/Success4.png'
import	Success4	from  	'../assets/2022/Success1/Success5.png'
import	Success5	from  	'../assets/2022/Success1/Success6.png'
import	Success6	from  	'../assets/2022/Success1/Success7.png'
import	Success7	from  	'../assets/2022/Success1/Success8.png'
import	Success8	from  	'../assets/2022/Success1/Success9.png'
import	Success9	from  	'../assets/2022/Success1/Success10.png'
import	Success10	from  	'../assets/2022/Success1/Success11.png'
import	Success11	from  	'../assets/2022/Success1/Success12.png'
import	Success12	from  	'../assets/2022/Success1/Success13.png'
import	Success13	from  	'../assets/2022/Success1/Success14.jpg'
import	Success14	from  	'../assets/2022/Success1/Success15.jpg'
import	Success15	from  	'../assets/2022/Success1/Success16.jpg'
import	Success16	from  	'../assets/2022/Success1/Success17.jpg'
import	Success17	from  	'../assets/2022/Success1/Success18.jpg'
import	Success18	from  	'../assets/2022/Success1/Success19.jpg'
import	Success19	from  	'../assets/2022/Success1/Success20.jpg'
import	Success20	from  	'../assets/2022/Success1/Success21.jpg'
import	Success21	from  	'../assets/2022/Success1/Success22.jpg'
import	Success22	from  	'../assets/2022/Success1/Success23.jpg'
import	Success23	from  	'../assets/2022/Success1/Success24.jpg'
import	Success24	from  	'../assets/2022/Success1/Success25.jpg'
import	Success25	from  	'../assets/2022/Success1/Success26.jpg'
import	Success26	from  	'../assets/2022/Success1/Success27.jpg'
import	Success27	from  	'../assets/2022/Success1/Success28.jpg'
import	Success28	from  	'../assets/2022/Success1/Success29.jpg'
import	Success29	from  	'../assets/2022/Success1/Success30.jpg'
import	Success30	from  	'../assets/2022/Success1/Success31.jpg'
import	Success31	from  	'../assets/2022/Success1/Success32.jpg'
import	Success32	from  	'../assets/2022/Success1/Success33.jpg'
import	Success33	from  	'../assets/2022/Success1/Success34.jpg'
import	Success34	from  	'../assets/2022/Success1/Success1.png'
import	Success35	from  	'../assets/2022/Success2/image (2).png'
import	Success36	from  	'../assets/2022/Success2/image.png'
import	Success37	from  	'../assets/2022/Success2/image0.png'
import	Success38	from  	'../assets/2022/Success2/IMG_3754.jpg'
import	Success39	from  	'../assets/2022/Success2/IMG_7210.jpg'
import	Success40	from  	'../assets/2022/Success2/IMG_9418.jpg'
import	Success41	from  	'../assets/2022/Success2/Screenshot_20221220_105136.jpg'
import	Success42	from  	'../assets/2022/Success2/share_pic__2022-12-22_21_34_08.jpg'
import	Success43	from  	'../assets/2022/Success2/image (1).png'
import	Success44	from  	'../assets/2022/Success3/460517DA-A3B7-41FC-B3B5-4DB79A9388C1.jpg'
import	Success45	from  	'../assets/2022/Success3/C2C97EC6-B8AA-4457-BE26-6AB8395A68F4.jpg'
import	Success46	from  	'../assets/2022/Success3/CHZ.jpg'
import	Success47	from  	'../assets/2022/Success3/ETH (1).jpg'
import	Success48	from  	'../assets/2022/Success3/ETH.jpg'
import	Success49	from  	'../assets/2022/Success3/image (1).png'
import	Success50	from  	'../assets/2022/Success3/image (2).png'
import	Success51	from  	'../assets/2022/Success3/image (3).png'
import	Success52	from  	'../assets/2022/Success3/image (4).png'
import	Success53	from  	'../assets/2022/Success3/image (5).png'
import	Success54	from  	'../assets/2022/Success3/image (6).png'
import	Success55	from  	'../assets/2022/Success3/image (7).png'
import	Success56	from  	'../assets/2022/Success3/image (8).png'
import	Success57	from  	'../assets/2022/Success3/image (9).png'
import	Success58	from  	'../assets/2022/Success3/image (10).png'
import	Success59	from  	'../assets/2022/Success3/image (11).png'
import	Success60	from  	'../assets/2022/Success3/image (12).png'
import	Success61	from  	'../assets/2022/Success3/image (13).png'
import	Success62	from  	'../assets/2022/Success3/image (14).png'
import	Success63	from  	'../assets/2022/Success3/image (15).png'
import	Success64	from  	'../assets/2022/Success3/image (16).png'
import	Success65	from  	'../assets/2022/Success3/image (17).png'
import	Success66	from  	'../assets/2022/Success3/image (18).png'
import	Success67	from  	'../assets/2022/Success3/image.png'
import	Success68	from  	'../assets/2022/Success3/image0.jpg'
import	Success69	from  	'../assets/2022/Success3/image0.png'
import	Success70	from  	'../assets/2022/Success3/IMG_4969.jpg'
import	Success71	from  	'../assets/2022/Success3/IMG_5040.jpg'
import	Success72	from  	'../assets/2022/Success3/IMG_5041.jpg'
import	Success73	from  	'../assets/2022/Success3/IMG_5042.jpg'
import	Success74	from  	'../assets/2022/Success3/IMG_5043.jpg'
import	Success75	from  	'../assets/2022/Success3/IMG_7247.jpg'
import	Success76	from  	'../assets/2022/Success3/IMG_7711.jpg'
import	Success77	from  	'../assets/2022/Success3/IMG_7719.jpg'
import	Success78	from  	'../assets/2022/Success3/IMG_7720.jpg'
import	Success79	from  	'../assets/2022/Success3/IMG_7773.jpg'
import	Success80	from  	'../assets/2022/Success3/IMG_7780.jpg'
import	Success81	from  	'../assets/2022/Success3/IMG_7790.jpg'
import	Success82	from  	'../assets/2022/Success3/IMG_7794.jpg'
import	Success83	from  	'../assets/2022/Success3/IMG_7902.jpg'
import	Success84	from  	'../assets/2022/Success3/IMG_7903.jpg'
import	Success85	from  	'../assets/2022/Success3/IMG_7934.jpg'
import	Success86	from  	'../assets/2022/Success3/IMG_7936.jpg'
import	Success87	from  	'../assets/2022/Success3/IMG_8020.jpg'
import	Success88	from  	'../assets/2022/Success3/IMG_8021.jpg'
import	Success89	from  	'../assets/2022/Success3/IMG_9448.jpg'
import	Success90	from  	'../assets/2022/Success3/MXC_POSTER_1.png'
import	Success91	from  	'../assets/2022/Success3/orca-image--1449924175.jpg'
import	Success92	from  	'../assets/2022/Success3/orca-image--1591155245.jpg'
import	Success93	from  	'../assets/2022/Success3/Screenshot_20221209_220109.jpg'
import	Success94	from  	'../assets/2022/Success3/share_pic__2022-11-08_13_26_04.jpg'
import	Success95	from  	'../assets/2022/Success3/share_pic__2022-11-09_17_26_13.jpg'
import	Success96	from  	'../assets/2022/Success3/share_pic__2022-11-09_19_35_48.jpg'
import	Success97	from  	'../assets/2022/Success3/share_pic__2022-11-10_17_27_11.jpg'
import	Success98	from  	'../assets/2022/Success3/share_pic__2022-11-14_14_57_41.jpg'
import	Success99	from  	'../assets/2022/Success3/share_pic__2022-11-24_22_19_48.jpg'
import	Success100	from  	'../assets/2022/Success3/share_pic__2022-11-28_14_45_48.jpg'
import	Success101	from  	'../assets/2022/Success3/share-poster-1667459363938.jpg'
import	Success102	from  	'../assets/2022/Success3/share-poster-1667559460228.jpg'
import	Success103	from  	'../assets/2022/Success3/share-poster-1667559464015.jpg'
import	Success104	from  	'../assets/2022/Success3/share-poster-1667877650817.jpg'
import	Success105	from  	'../assets/2022/Success3/share-poster-1667970725116.jpg'
import	Success106	from  	'../assets/2022/Success3/share-poster-1668176706944.jpg'
import	Success107	from  	'../assets/2022/Success3/share-poster-1668496821142.jpg'
import	Success108	from  	'../assets/2022/Success3/share-poster-1670474052862.jpg'
import	Success109	from  	'../assets/2022/Success3/unknown.png'
import	Success110	from  	'../assets/2023/Success1/image (1).png'
import	Success111	from  	'../assets/2023/Success1/image (2).png'
import	Success112	from  	'../assets/2023/Success1/image (3).png'
import	Success113	from  	'../assets/2023/Success1/image (4).png'
import	Success114	from  	'../assets/2023/Success1/image (5).png'
import	Success115	from  	'../assets/2023/Success1/image (6).png'
import	Success116	from  	'../assets/2023/Success1/image (7).png'
import	Success117	from  	'../assets/2023/Success1/image (8).png'
import	Success118	from  	'../assets/2023/Success1/image (9).png'
import	Success119	from  	'../assets/2023/Success1/image (10).png'
import	Success120	from  	'../assets/2023/Success1/image (11).png'
import	Success121	from  	'../assets/2023/Success1/image (12).png'
import	Success122	from  	'../assets/2023/Success1/image (13).png'
import	Success123	from  	'../assets/2023/Success1/image (14).png'
import	Success124	from  	'../assets/2023/Success1/image (15).png'
import	Success125	from  	'../assets/2023/Success1/image (16).png'
import	Success126	from  	'../assets/2023/Success1/image (17).png'
import	Success127	from  	'../assets/2023/Success1/image (18).png'
import	Success128	from  	'../assets/2023/Success1/image (19).png'
import	Success129	from  	'../assets/2023/Success1/image (20).png'
import	Success130	from  	'../assets/2023/Success1/image (21).png'
import	Success131	from  	'../assets/2023/Success1/image (22).png'
import	Success132	from  	'../assets/2023/Success1/image (23).png'
import	Success133	from  	'../assets/2023/Success1/image (24).png'
import	Success134	from  	'../assets/2023/Success1/image (25).png'
import	Success135	from  	'../assets/2023/Success1/image (26).png'
import	Success136	from  	'../assets/2023/Success1/image (27).png'
import	Success137	from  	'../assets/2023/Success1/image (28).png'
import	Success138	from  	'../assets/2023/Success1/image (29).png'
import	Success139	from  	'../assets/2023/Success1/image (30).png'
import	Success140	from  	'../assets/2023/Success1/image (31).png'
import	Success141	from  	'../assets/2023/Success1/image (32).png'
import	Success142	from  	'../assets/2023/Success1/image (33).png'
import	Success143	from  	'../assets/2023/Success1/image (34).png'
import	Success144	from  	'../assets/2023/Success1/image (35).png'
import	Success145	from  	'../assets/2023/Success1/image (36).png'
import	Success146	from  	'../assets/2023/Success1/image (37).png'
import	Success147	from  	'../assets/2023/Success1/image (38).png'
import	Success148	from  	'../assets/2023/Success1/image (39).png'
import	Success149	from  	'../assets/2023/Success1/image (40).png'
import	Success150	from  	'../assets/2023/Success1/image (41).png'
import	Success151	from  	'../assets/2023/Success1/image (42).png'
import	Success152	from  	'../assets/2023/Success1/image (43).png'
import	Success153	from  	'../assets/2023/Success1/image (44).png'
import	Success154	from  	'../assets/2023/Success1/image (45).png'
import	Success155	from  	'../assets/2023/Success1/image (46).png'
import	Success156	from  	'../assets/2023/Success1/image.png'
import	Success157	from  	'../assets/2023/Success2/image (13).png'
import	Success158	from  	'../assets/2023/Success2/image (14).png'
import	Success159	from  	'../assets/2023/Success2/image (15).png'
import	Success160	from  	'../assets/2023/Success2/image (16).png'
import	Success161	from  	'../assets/2023/Success2/image (17).png'
import	Success162	from  	'../assets/2023/Success2/image (18).png'
import	Success163	from  	'../assets/2023/Success2/image (19).png'
import	Success164	from  	'../assets/2023/Success2/image (20).png'
import	Success165	from  	'../assets/2023/Success2/image (21).png'
import	Success166	from  	'../assets/2023/Success2/image (22).png'
import	Success167	from  	'../assets/2023/Success2/image (23).png'
import	Success168	from  	'../assets/2023/Success2/image (24).png'
import	Success169	from  	'../assets/2023/Success2/image (25).png'
import	Success170	from  	'../assets/2023/Success2/image (26).png'
import	Success171	from  	'../assets/2023/Success2/image (27).png'
import	Success172	from  	'../assets/2023/Success2/image (28).png'
import	Success173	from  	'../assets/2023/Success2/image (29).png'
import	Success174	from  	'../assets/2023/Success2/image (30).png'
import	Success175	from  	'../assets/2023/Success2/image (31).png'
import	Success176	from  	'../assets/2023/Success2/image.png'
import	Success177	from  	'../assets/2023/Success2/image (1).png'
import	Success178	from  	'../assets/2023/Success2/image (2).png'
import	Success179	from  	'../assets/2023/Success2/image (3).png'
import	Success180	from  	'../assets/2023/Success2/image (4).png'
import	Success181	from  	'../assets/2023/Success2/image (5).png'
import	Success182	from  	'../assets/2023/Success2/image (6).png'
import	Success183	from  	'../assets/2023/Success2/image (7).png'
import	Success184	from  	'../assets/2023/Success2/image (8).png'
import	Success185	from  	'../assets/2023/Success2/image (9).png'
import	Success186	from  	'../assets/2023/Success2/image (10).png'
import	Success187	from  	'../assets/2023/Success2/image (11).png'
import	Success188	from  	'../assets/2023/Success2/image (12).png'
import	Success189	from  	'../assets/2023/Success3/image (1).png'
import	Success190	from  	'../assets/2023/Success3/image (2).png'
import	Success191	from  	'../assets/2023/Success3/image (3).png'
import	Success192	from  	'../assets/2023/Success3/image (4).png'
import	Success193	from  	'../assets/2023/Success3/image (5).png'
import	Success194	from  	'../assets/2023/Success3/image (6).png'
import	Success195	from  	'../assets/2023/Success3/image (7).png'
import	Success196	from  	'../assets/2023/Success3/image (8).png'
import	Success197	from  	'../assets/2023/Success3/image (9).png'
import	Success198	from  	'../assets/2023/Success3/image (10).png'
import	Success199	from  	'../assets/2023/Success3/image (11).png'
import	Success200	from  	'../assets/2023/Success3/image (12).png'
import	Success201	from  	'../assets/2023/Success3/image (13).png'
import	Success202	from  	'../assets/2023/Success3/image (14).png'
import	Success203	from  	'../assets/2023/Success3/image (15).png'
import	Success204	from  	'../assets/2023/Success3/image (16).png'
import	Success205	from  	'../assets/2023/Success3/image (17).png'
import	Success206	from  	'../assets/2023/Success3/image (18).png'
import	Success207	from  	'../assets/2023/Success3/image (19).png'
import	Success208	from  	'../assets/2023/Success3/image (20).png'
import	Success209	from  	'../assets/2023/Success3/image.png'
import	Success210	from  	'../assets/2023/Success4/image (3).png'
import	Success211	from  	'../assets/2023/Success4/image (4).png'
import	Success212	from  	'../assets/2023/Success4/image (5).png'
import	Success213	from  	'../assets/2023/Success4/image (6).png'
import	Success214	from  	'../assets/2023/Success4/image.png'
import	Success215	from  	'../assets/2023/Success4/image (1).png'
import	Success216	from  	'../assets/2023/Success4/image (2).png'
import	Success217	from  	'../assets/2023/Success5/321034363_3580982815458720_8946034030106385379_n.png'
import	Success218	from  	'../assets/2023/Success5/323530008_884482726331751_4807373068589234390_n.png'
import	Success219	from  	'../assets/2023/Success5/IMG_3288.jpg'
import	Success220	from  	'../assets/2023/Success5/IMG_3289.jpg'
import	Success221	from  	'../assets/2023/Success5/IMG_5197.jpg'
import	Success222	from  	'../assets/2023/Success5/IMG_6020.jpg'
import	Success223	from  	'../assets/2023/Success5/share_pic__2023-01-04_22_16_04.jpg'
import	Success224	from  	'../assets/2023/Success5/share_pic__2023-01-06_14_54_18.jpg'
import	Success225	from  	'../assets/2023/Success7/image (12).png'
import	Success226	from  	'../assets/2023/Success7/image.png'
import	Success227	from  	'../assets/2023/Success7/image (1).png'
import	Success228	from  	'../assets/2023/Success7/image (2).png'
import	Success229	from  	'../assets/2023/Success7/image (3).png'
import	Success230	from  	'../assets/2023/Success7/image (4).png'
import	Success231	from  	'../assets/2023/Success7/image (5).png'
import	Success232	from  	'../assets/2023/Success7/image (6).png'
import	Success233	from  	'../assets/2023/Success7/image (7).png'
import	Success234	from  	'../assets/2023/Success7/image (8).png'
import	Success235	from  	'../assets/2023/Success7/image (9).png'
import	Success236	from  	'../assets/2023/Success7/image (10).png'
import	Success237	from  	'../assets/2023/Success7/image (11).png'
import	Success238	from  	'../assets/2023/Success8/image (1).png'
import	Success239	from  	'../assets/2023/Success8/image (2).png'
import	Success240	from  	'../assets/2023/Success8/image (3).png'
import	Success241	from  	'../assets/2023/Success8/image (4).png'
import	Success242	from  	'../assets/2023/Success8/image (5).png'
import	Success243	from  	'../assets/2023/Success8/image (6).png'
import	Success244	from  	'../assets/2023/Success8/image (7).png'
import	Success245	from  	'../assets/2023/Success8/image (8).png'
import	Success246	from  	'../assets/2023/Success8/image (9).png'
import	Success247	from  	'../assets/2023/Success8/image (10).png'
import	Success248	from  	'../assets/2023/Success8/image (11).png'
import	Success249	from  	'../assets/2023/Success8/image (12).png'
import	Success250	from  	'../assets/2023/Success8/image (13).png'
import	Success251	from  	'../assets/2023/Success8/image (14).png'
import	Success252	from  	'../assets/2023/Success8/image (15).png'
import	Success253	from  	'../assets/2023/Success8/image (16).png'
import	Success254	from  	'../assets/2023/Success8/image (17).png'
import	Success255	from  	'../assets/2023/Success8/image (18).png'
import	Success256	from  	'../assets/2023/Success8/image (19).png'
import	Success257	from  	'../assets/2023/Success8/image (20).png'
import	Success258	from  	'../assets/2023/Success8/image (21).png'
import	Success259	from  	'../assets/2023/Success8/image (22).png'
import	Success260	from  	'../assets/2023/Success8/image (23).png'
import	Success261	from  	'../assets/2023/Success8/image (24).png'
import	Success262	from  	'../assets/2023/Success8/image (25).png'
import	Success263	from  	'../assets/2023/Success8/image (26).png'
import	Success264	from  	'../assets/2023/Success8/image (27).png'
import	Success265	from  	'../assets/2023/Success8/image (28).png'
import	Success266	from  	'../assets/2023/Success8/image (29).png'
import	Success267	from  	'../assets/2023/Success8/image (30).png'
import	Success268	from  	'../assets/2023/Success8/image (31).png'
import	Success269	from  	'../assets/2023/Success8/image (32).png'
import	Success270	from  	'../assets/2023/Success8/image (33).png'
import	Success271	from  	'../assets/2023/Success8/image (34).png'
import	Success272	from  	'../assets/2023/Success8/image (35).png'
import	Success273	from  	'../assets/2023/Success8/image (36).png'
import	Success274	from  	'../assets/2023/Success8/image (37).png'
import	Success275	from  	'../assets/2023/Success8/image.png'

const Success = () => {
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null);
  const [visiblePhotos, setVisiblePhotos] = useState(20);
  const photos = [
        Success1,
    Success2,
    Success3,
    Success4,
    Success5,
    Success6,
    Success7,
    Success8,
    Success9,
    Success10,
    Success11,
    Success12,
    Success13,
    Success14,
    Success15,
    Success16,
    Success17,
    Success18,
    Success19,
    Success20,
    Success21,
    Success22,
    Success23,
    Success24,
    Success25,
    Success26,
    Success27,
    Success28,
    Success29,
    Success30,
    Success31,
    Success32,
    Success33,
    Success34,
    Success35,
    Success36,
    Success37,
    Success38,
    Success39,
    Success40,
    Success41,
    Success42,
    Success43,
    Success44,
    Success45,
    Success46,
    Success47,
    Success48,
    Success49,
    Success50,
    Success51,
    Success52,
    Success53,
    Success54,
    Success55,
    Success56,
    Success57,
    Success58,
    Success59,
    Success60,
    Success61,
    Success62,
    Success63,
    Success64,
    Success65,
    Success66,
    Success67,
    Success68,
    Success69,
    Success70,
    Success71,
    Success72,
    Success73,
    Success74,
    Success75,
    Success76,
    Success77,
    Success78,
    Success79,
    Success80,
    Success81,
    Success82,
    Success83,
    Success84,
    Success85,
    Success86,
    Success87,
    Success88,
    Success89,
    Success90,
    Success91,
    Success92,
    Success93,
    Success94,
    Success95,
    Success96,
    Success97,
    Success98,
    Success99,
    Success100,
    Success101,
    Success102,
    Success103,
    Success104,
    Success105,
    Success106,
    Success107,
    Success108,
    Success109,
    Success110,
    Success111,
    Success112,
    Success113,
    Success114,
    Success115,
    Success116,
    Success117,
    Success118,
    Success119,
    Success120,
    Success121,
    Success122,
    Success123,
    Success124,
    Success125,
    Success126,
    Success127,
    Success128,
    Success129,
    Success130,
    Success131,
    Success132,
    Success133,
    Success134,
    Success135,
    Success136,
    Success137,
    Success138,
    Success139,
    Success140,
    Success141,
    Success142,
    Success143,
    Success144,
    Success145,
    Success146,
    Success147,
    Success148,
    Success149,
    Success150,
    Success151,
    Success152,
    Success153,
    Success154,
    Success155,
    Success156,
    Success157,
    Success158,
    Success159,
    Success160,
    Success161,
    Success162,
    Success163,
    Success164,
    Success165,
    Success166,
    Success167,
    Success168,
    Success169,
    Success170,
    Success171,
    Success172,
    Success173,
    Success174,
    Success175,
    Success176,
    Success177,
    Success178,
    Success179,
    Success180,
    Success181,
    Success182,
    Success183,
    Success184,
    Success185,
    Success186,
    Success187,
    Success188,
    Success189,
    Success190,
    Success191,
    Success192,
    Success193,
    Success194,
    Success195,
    Success196,
    Success197,
    Success198,
    Success199,
    Success200,
    Success201,
    Success202,
    Success203,
    Success204,
    Success205,
    Success206,
    Success207,
    Success208,
    Success209,
    Success210,
    Success211,
    Success212,
    Success213,
    Success214,
    Success215,
    Success216,
    Success217,
    Success218,
    Success219,
    Success220,
    Success221,
    Success222,
    Success223,
    Success224,
    Success225,
    Success226,
    Success227,
    Success228,
    Success229,
    Success230,
    Success231,
    Success232,
    Success233,
    Success234,
    Success235,
    Success236,
    Success237,
    Success238,
    Success239,
    Success240,
    Success241,
    Success242,
    Success243,
    Success244,
    Success245,
    Success246,
    Success247,
    Success248,
    Success249,
    Success250,
    Success251,
    Success252,
    Success253,
    Success254,
    Success255,
    Success256,
    Success257,
    Success258,
    Success259,
    Success260,
    Success261,
    Success262,
    Success263,
    Success264,
    Success265,
    Success266,
    Success267,
    Success268,
    Success269,
    Success270,
    Success271,
    Success272,
    Success273,
    Success274,
    Success275
  ];

  const openPhoto = (index) => {
    setSelectedPhotoIndex(index);
  };

  const closePhoto = () => {
    setSelectedPhotoIndex(null);
  };

  const goToPreviousPhoto = () => {
    setSelectedPhotoIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : photos.length - 1));
  };

  const goToNextPhoto = () => {
    setSelectedPhotoIndex((prevIndex) => (prevIndex < photos.length - 1 ? prevIndex + 1 : 0));
  };

  const showMorePhotos = () => {
    setVisiblePhotos((prevVisiblePhotos) => prevVisiblePhotos + 20);
  };

  return (
    <div className="container mx-auto mt-[8rem]">
      <h2 className="text-5xl font-mytom mb-4 text-center">SL SUCCESS</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {photos.slice(0, visiblePhotos).map((photo, index) => (
          <div key={index} className="relative">
            <img
              src={photo}
              alt={`Success ${index}`}
              className="w-full h-40 object-cover cursor-pointer"
              onClick={() => openPhoto(index)}
            />
          </div>
        ))}
      </div>
      {visiblePhotos < photos.length && (
        <button
          className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-5"
          onClick={showMorePhotos}
        >
          Show More
        </button>
      )}
      {selectedPhotoIndex !== null && (
        <div className="fixed inset-0 flex justify-center items-center bg-white bg-opacity-75">
          <div className="relative">
            <button
              className="absolute top-1/2 left-2 text-3xl text-white p-2 rounded-full transform -translate-y-1/2"
              onClick={goToPreviousPhoto}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <button
              className="absolute top-1/2 right-2 text-3xl text-white p-2 rounded-full transform -translate-y-1/2"
              onClick={goToNextPhoto}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
            <button
              className="absolute top-2 right-10 text-3xl text-white p-2 rounded-full"
              onClick={closePhoto}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <img
              src={photos[selectedPhotoIndex]}
              alt={`Success ${selectedPhotoIndex}`}
              className="w-full h-96 object-contain"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Success;
