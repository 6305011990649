import React, { useState } from 'react';
import SLPass from '../assets/SLPass.png'
import BG from '../assets/faq.png';
import FolderSlide from './FolderSlide';

import may2020 from '../assets/may2020.png';
import sept2020 from '../assets/sept2020.png';
import dec2020 from '../assets/dec2020.png';
import july2021 from '../assets/july2021.png';
import sept2021 from '../assets/sept2021.png';
import jan2022 from '../assets/jan2022.png';

const titles = [
  'May 2020',
  'Sept 2020',
  'Dec 2020',
  'July 2021',
  'Sept 2021',
  'Jan 2022',
];

const images = [
  may2020,
  sept2020,
  dec2020,
  july2021,
  sept2021,
  jan2022,
];


const About = () => {
  const [selectedQuestion, setSelectedQuestion] = useState('What is SL?');


  const questions = [
    { id: 1, title: 'What is SL?' },
    { id: 2, title: 'Why We Become Different?' },
    { id: 3, title: 'How it all Started' },
  ];

  const handleQuestionClick = (question) => {
    setSelectedQuestion(question.title);
  };



  return (
 
    <div className="flex flex-wrap justify-center mx-auto mt-[6rem]">

      {/* Left column */}
      <div className="w-full md:w-1/2 px-4 py-4 mb-[5rem]">
        <div className="flex flex-col justify-start">
          {selectedQuestion === 'What is SL?' && (
            <div className="my-2 py-2 mx-auto">
              <div className="text-white text-5xl font-bold font-myfont  leading-none h-5">What is SL?</div>
            </div>
          )}

          {selectedQuestion === 'Why We Become Different?' && (
            <div className="my-2 py-2 mx-auto">
              <div className="text-white text-5xl font-bold font-myfont  leading-none h-5">Why We Become Different?</div>
            </div>
          )}

          {selectedQuestion === 'How it all Started' && (
            <div className="my-2 py-2 mx-auto">
              <div className="text-white text-5xl font-bold font-myfont leading-none h-5">How it all Started</div>
            </div>
          )}
        </div>
      </div>



      {/* Right column */}
      <div className="w-full md:w-1/2 px-4 py-4">
        <div className="flex flex-col justify-start">
          {questions.map((question) => (
            <div
              key={question.id}
              className={`my-2 py-2 mx-5 border-l-8 bg-white bg-opacity-50 rounded-lg ${
                selectedQuestion === question.title
                  ? 'border-blue-400 bg-gray-100 '
                  : 'border-transparent hover:bg-gray-200 cursor-pointer'
              }`}
              onClick={() => handleQuestionClick(question)}
            >
              <div className="text-lg font-medium mx-4">{question.title}</div>
            </div>
          ))}
        </div>
      </div>



      {selectedQuestion === 'What is SL?' && (
         <div className="flex flex-col lg:flex-row">
         <div className="flex-1 flex justify-center items-center md:pl-8 overflow-hidden">
           <img src={SLPass} alt="Your Logo GIF" className="w-[300px] h-[auto] md:w-[400px] md:h-[auto] rounded-md my-[5rem]" />
   
         </div>
  <div className="lg:mt-[100px]  lg:mb-[100px]  border-l border-white "></div>

    <div className="lg:w-1/2 lg:mt-[200px]  lg:ml-4 justify-center items-center  ">
         <div className="flex-1 flex flex-col justify-center max-[600px]:items-center text-left md:pl-8">
           <div className="max-[600px]:max-w-md text-left mx-8 lg:mt-[-5rem]  max-[600px]:text-left max-[600px]:mx-8">
           <p className="text-white"> We are a 222 alpha pass community that not only specializes in Crypto and NFTs. We also tackle every possible income generating vehicle that will tune with our members may it be in Web2 or Web3. <span className="block">With our vast knowledge on NFTs, crypto, and sneakers, we aim to be an exclusive yet interactive group.</span></p>
          </div>
        <div className="flex gap-4 mt-2 max-[600px]:mb-8 ">
        <a href="https://slnotifyio.notion.site/Overview-129560ec863441d49dd6e7e1e27e39ec">
             <button className="flex items-center justify-center border-2 border-blue-500 bg-blue-500 hover:text-white text-white text-[1rem] font-bold  px-4 rounded-full lg:ml-8">
             LEARN MORE
             </button>
             </a>
              </div>
              <div className="max-[600px]:text-center text-white text-1xl font-myfont  leading-none h-5 lg:mt-[6rem] mt-6 mb-[5rem]">Are you ready to join the Winning Team?</div>
         
    </div>
  </div>
  </div>
       



          )}

        {selectedQuestion === 'Why We Become Different?' && (
          <div className="flex items-center relative">
            <img src={BG} alt="Faq" className="w-auto h-auto left-131 top-303 my-[5rem]" />    
        </div>
          
          )}

      {selectedQuestion === 'How it all Started' && (
      <div className="h-screen flex flex-col justify-center ">
            <FolderSlide images={images} titles={titles} />
</div>


 
          )}




    </div>



    
    
  );
};

export default About;
