import React, { useState } from 'react';
import Success1 from '../assets/Sllss.jpg';
import Patriciooow from './patriciooow';
import Kruncchh from './kruncchh';
import DembeThe from './dembethe';
import Longadogs from './longadogs';
import Rawrdwn from './rawrdwn';
import Lachan from './lachan';

function BannerSections() {
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="flex flex-col lg:flex-row">
     
        <div className="flex-1 flex justify-center items-center md:pl-8 overflow-hidden">
         <a  href='https://www.instagram.com/p/Cm1vo8Bh3XH/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=='>
          <img
            src={Success1}
            alt="Your Logo GIF"
            className="w-[auto] h-[auto] md:w-[auto] md:h-[auto] rounded-md mt-12"
          />
          </a>
        </div>

    
      <div className="lg:mt-[100px]  ml-5 lg:mb-[100px]  border-l border-white"></div>

      <div className="lg:w-1/2 mt-[100px]  lg:ml-4 justify-center items-center">
       
        <div className={`overflow-y-auto md:max-h-[35rem] lg:mr-[50px] ${showMore ? '' : 'md:max-h-[35rem]'}`}>
          <Patriciooow />
          <Kruncchh />
          <DembeThe />
          {showMore && (
            <>
              <Longadogs />
              <Rawrdwn />
              <Lachan />
            </>
          )}
        </div>
        {!showMore && (
          <div className="mt-4 max-[600px]:mb-[50px] text-right lg:ml-[20px] my-11">
            <button
              onClick={() => setShowMore(true)}
              className="flex items-center justify-center border-4 max-[600px]:text-sm md:text-[.8rem] border-[#1AB6ED] hover:bg-blue-500 hover:text-white text-[#1AB6ED] font-myfont max-[600px]:mr-[20px]  max-[600px]:px-[.5rem] md:px-[1.5rem] rounded-full"
            >
              View All
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default BannerSections;
