import React, { useState } from 'react';


function Kruncchh() {
  const [showFullReview, setShowFullReview] = useState(false);

  const toggleShowFullReview = () => {
    setShowFullReview(!showFullReview);
  };

  return (
    <div className="flex items-center border border-white mr-4 ml-4 md:h-[11rem]">
<img
  src="https://pbs.twimg.com/profile_images/1670326212663869441/XxqCJJk8_400x400.jpg"
  alt="Profile"
  className="w-[8rem] h-[8rem] rounded-full object-cover mr-4 ml-4"
/>
      <div className="flex flex-col mt-2">
        <p className="text-white mr-4">
          {showFullReview
            ? 'I joined SL around October 2021, CookingAstrid  won a giveaway (invite 1 people) and invited me to SL. '
            : 'I joined SL around October 2021, CookingAstrid  won a giveaway (invite 1 people) and invited me to SL....'}
          {!showFullReview && (
            <button
              className="text-blue-500 hover:underline font-bold py-2 px-4 rounded-full"
              onClick={toggleShowFullReview}
            >
              Read More
            </button>
          )}
        </p>
        <div className="mt-4 max-[600px]:mb-[50px] text-left ">
          <a
            href="https://twitter.com/kruncchh"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline font-bold py-2 px-4 rounded-full "
          >
            @kruncchh
          </a>
        </div>
      </div>
      {showFullReview && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
              onClick={toggleShowFullReview}
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
        <div className="bg-black px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div
                    className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10"
                  >
<img
  src="https://pbs.twimg.com/profile_images/1670326212663869441/XxqCJJk8_400x400.jpg"
  alt="Profile"
  className="w-[5rem] h-[5rem] rounded-full object-cover mr-4 ml-4"
/>
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3
                                           className="text-lg leading-6 font-medium text-white font-mytom"
                                           id="modal-headline"
                                         >
                        kruncchh
                        </h3>
                        <div className="mt-2 overflow-y-auto max-h-[30rem] lg:mr-[20px]">
                       <p className="text-white mb-2">
                       I joined SL around October 2021, CookingAstrid  won a giveaway (invite 1 people) and invited me to SL. At first I thought SL is just a sneaker cook group, but later found out that they are into crypto and NFTs as well.
                        </p>
                        <p className="text-white mb-2">
                      Earned my first million here at the age of 20 with the help of SL community. My family was struggling financially because we got caught in an investment scam, too many debts, plus pandemic happened. But with the help of SL fam I was able to bounce back and help my mom financially and payed some of her debts. I also help her with bills and bought my dad a motorcycle too.  From my last money 30k to milli real quick. Many puyat and grinding but its all worth it. 
                      </p>
                      <p className="text-white mb-2">
                      Met a lot of people here and made some friends too, though Im lil shy they welcomed me as a tropa and made sure Im good and not being OP when Im with them.
                      </p>
                      <p className="text-white mb-2">
                      Took some L's too but thats part of the game, bounce back soon
                      Bear or bull, people make money here.
                      Thank you to this community.
                      </p>
                      <p className="text-white mb-2">
                      Special thanks to the boss man for helping me out in NFT and appreciate yall., Sins, ChambaloT, HugeHen 
                      Thanks boss Rev   for creating this group and giving us more opportunities and mulla
                      </p>
                      <p className="text-white mb-2">
                      S/O to CookingAstrid cheriffer mcnuggets 

                      TY SL FROM DA HEART

                        </p>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div className="bg-black px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                                       type="button"
                                       className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                       onClick={toggleShowFullReview}
                                     >
                        Close
                        </button>
                        </div>
                        </div>
                        </div>
                        </div>
                        )}
                        </div>
                        );
                        }
                        
                        export default Kruncchh;
