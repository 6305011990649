import React from 'react';
import Logo from '../assets/logo.gif';
import Event from '../assets/events.png';
import { Link } from 'react-router-dom';

import BannerSection from './bannersection';
import BannerSections from './bannersections';

function Banner(props) {
  return (
    <>
    <div className=" w-screen h-screen mx-4">
 <div className="flex flex-col md:flex-row">

      <div className="flex-1 flex flex-col justify-center items-center mt-[4.5rem] ">
        <h1 className="text-5xl md:text-[6rem] lg:mb-2 font-mytom md:ml-[30px]">
          <p className="text-base md:text-2xl font-mytom">EXPERIENCE THE METAVERSE</p>
          STAY AHEAD,&nbsp;<span className="block">INSTANTLY</span>
        </h1>
        <div className="flex gap-[40px] md:mr-[180px] max-[600px]:mt-[10px]">
        <Link to="https://staking.slnotify.io/" target="_blank">
          <button className="flex items-center justify-center max-[600px]:text-sm md:text-[.8rem]  border-4 md:mr-[.5rem]  border-[#1AB6ED] hover:bg-blue-500 hover:text-white text-[#1AB6ED] font-myfont max-[600px]:ml-[30px] lg:px-[3.5rem] max-[600px]:px-[1rem] md:px-[2rem] rounded-full">
            STAKING
          </button>
          </Link>
          <button className="flex items-center justify-center border-4 max-[600px]:text-sm md:text-[.8rem] border-[#1AB6ED] hover:bg-blue-500 hover:text-white text-[#1AB6ED] font-myfont max-[600px]:mr-[20px]  max-[600px]:px-[.5rem] md:px-[1.5rem] rounded-full">
            MARKETPLACE
          </button>
        </div>
      </div>
      <div className="flex-1 flex justify-center items-center  mt-11">
        <img src={Logo} alt="Your Logo GIF" className="max-h-full max-w-1xl mb-11 md:max-w-3xl md:max-h-full" />
      </div> 
    </div>
  <div className="flex md:justify-center max-[600px]:overflow-x-auto max-[600px]:overflow-y-hidden  ">
    <div className="bg-[#011723] w-[450px] h-[200px] rounded-[3rem] mr-[2rem] ">
      <div className="flex items-center h-full max-[600px]:w-[400px]">
        <span className="md:text-[5rem] font-myfont mt-[2rem] ml-[2rem] mb-[8rem] max-[600px]:text-[3rem] ">181 </span>
        <span className="text-[1.5rem] font-myfont mb-[8rem] max-[600px]:text-[1rem]">/222</span>
      </div>
      <div className=" flex items-center h-full">
        <span className="text-[1rem] font-myfont mb-[16rem] ml-[2rem] max-[600px]:text-[.7rem]"># OF MINTED PASSES</span>
        <a href="https://mint.slnotify.io/">
        <svg className="text-[1rem] font-myfont mb-[17rem] ml-[7rem]"
          fill="currentColor"
          viewBox="0 0 16 16"
          height="2em"
          width="2em"
          {...props}
      >
      <path d="M8 0a8 8 0 110 16A8 8 0 018 0zM4.5 7.5a.5.5 0 000 1h5.793l-2.147 2.146a.5.5 0 00.708.708l3-3a.5.5 0 000-.708l-3-3a.5.5 0 10-.708.708L10.293 7.5H4.5z" />
      </svg>
      </a>
    </div>
  </div>
<div className="bg-[#011723] w-[450px] h-[200px] rounded-[3rem] mr-[2rem] ">
  <div className="flex items-center h-full max-[600px]:w-[400px]">
      <span className="md:text-[5rem] font-myfont mt-[2rem] ml-[2rem] mb-[8rem] max-[600px]:text-[3rem]">$2M</span>
    </div>
    <div className=" flex items-center h-full  ">
    <span className="text-[1rem] font-myfont mb-[16rem] ml-[2rem]  max-[600px]:text-[.7rem]">TOTAL REVENUE IN 2022</span>
    <a href="/success">
    <svg className="text-[1rem] font-myfont mb-[17rem] ml-[5.5rem]"
      fill="currentColor"
      viewBox="0 0 16 16"
      height="2em"
      width="2em"
      {...props}
    >
      
      <path d="M8 0a8 8 0 110 16A8 8 0 018 0zM4.5 7.5a.5.5 0 000 1h5.793l-2.147 2.146a.5.5 0 00.708.708l3-3a.5.5 0 000-.708l-3-3a.5.5 0 10-.708.708L10.293 7.5H4.5z" />
    </svg>
    </a>
    </div>
  </div>
    <div className="bg-[#011723] w-[450px] h-[200px] rounded-[3rem] mr-[2rem]"
         style={{ backgroundImage: `url(${Event})` }}>
     <div className="flex items-center h-full max-[600px]:w-[400px]">
      <span className="text-[4rem] font-myfont mt-[2rem] ml-[2rem] mb-[8rem] max-[600px]:text-[3rem]">EVENTS</span>
    </div>  
    <div className=" flex items-center h-full">
    <span className="text-[1rem] font-myfont mb-[16rem] ml-[2rem] max-[600px]:text-[.7rem]">Web2 with SL Notify</span>
    <a href="/events">
    <svg className="text-[1rem] font-myfont mb-[17rem] ml-[6.5rem]"
      fill="currentColor"
      viewBox="0 0 16 16"
      height="2em"
      width="2em"
      {...props}
    >
      
      <path d="M8 0a8 8 0 110 16A8 8 0 018 0zM4.5 7.5a.5.5 0 000 1h5.793l-2.147 2.146a.5.5 0 00.708.708l3-3a.5.5 0 000-.708l-3-3a.5.5 0 10-.708.708L10.293 7.5H4.5z" />
    </svg>
    </a>
    </div>     
          
  </div>
</div>
</div>

    <BannerSection />
    <BannerSections/>
    </>
  );
}

export default Banner;
