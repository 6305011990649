import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// components
import Banner from './components/Banner';
import Header from './components/Header';
import Nav from './components/Nav';
import About from './components/About';
import Services from './components/Services';
import Events from './components/events';
import Footer from './components/footer';
import Team from './components/team';
import Success from './components/success';

const App = () => {
  return (
    <BrowserRouter>
      <div className='bg-site bg-no-repeat bg-cover overflow-hidden'>
        <Header />
        <div style={{ paddingTop: '85px' }}>
          {/* Adjust the paddingTop value to match the height of your header */}
          <Routes>
            <Route path='/' element={<Banner />} />
            <Route path='/about' element={<Nav />} />
            <Route path='/team' element={<Team />} />
            <Route path='/result' element={<About />} />
            <Route path='/services' element={<Services />} />
            <Route path='/events' element={<Events />} />
            <Route path='/success' element={<Success />} />
          </Routes>
          {/* <div className='h-[4000px]'></div> */}
          <Footer/>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
